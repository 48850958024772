/* ----- HEADER ----- */

.header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/burger7.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;


    .hero-text-box {
        text-align: left;
        position: absolute;
        max-width: 900px;
        width: 100%;
        top: 50%;
        left: 60%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);


        p {
            color: white;
            width: 60%;
        }

        h1 {
            color: white;
            font-size: 50px;
            word-spacing: 4px;
            letter-spacing: 1px;
            margin-top: 0;
            margin-bottom: 20px;
        }



        .btn {
            color: white;
            padding: 10px 20px;
            background: hsla(44, 90%, 56%, 0.726);
            border-radius: 30px;
            font-family: 'Roboto Slab', serif;
            font-size: 18px;
        }

    }
}

/* ----- NAV ----- */


.header-wrapper {




    .navbar-dark .navbar-nav .nav-link {
        color: white;
        margin-right: 20px;


        &:hover {
            border-bottom: 4px solid hsla(44, 90%, 56%, 0.726);


        }




    }






    .nav-wrapper {
        // max-width: 1140px;

        // margin: 0 auto;


    }

    .logo {
        width: 83px;
        float: left;
        margin-left: 50px;
        margin-top: 2px;
        z-index: 99999;
        position: fixed;
        left: 0px;
    }

    .logoMobile {
        display: none;
    }

    .nav {
        float: right;
        margin-top: 25px;
        font-family: 'Roboto Slab', serif;
        font-size: 23px;




    }

    .fixed-top {


        background-color: #000000;
        margin-top: 0px;
        font-family: 'Roboto Slab', serif;
        font-size: 23px;




    }


}

button.menu-button.btn.btn-primary {
    display: none;
}


.mobile-nav {
    display: none;
}

.font-weight-500 {
    font-weight: 500;
}