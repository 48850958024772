.wrapper {
    background-color: rgb(0, 0, 0);
    padding-top: 40px;
    padding-bottom: 100px;

    //height: 100vh;

    h2 {
        color: #f4bf2b;


    }
}

.menu-wrapper {
    width: 90%;
    margin: 0 auto;
    padding-top: 40px;



    .card-container {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;


        .card {

            color: white;
            background-color: rgb(0, 0, 0);
            border-top: 3px solid #f4bf2b;
            padding-top: 20px;
            height: 310px;
            text-align: left;






            ul {
                margin-top: 5px;
                padding-left: 0px;
                padding-bottom: 20px;
            }

            li {
                list-style: none;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: left;

            }


            .card-title {
                padding-bottom: 10px;
                font-size: 25px;
                color: #f4bf2b;

            }

            .card-subtitle {
                padding-bottom: 10px;
                font-size: 20px;
                color: #f4be2b71;
            }

            .card-text {
                color: white;
                margin-top: 5px;

            }

            .text-bottom {
                position: absolute;
                bottom: 0px;
                color: #f4be2b71;




            }


        }
    }



}