@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto+Slab:wght@800&family=Rubik:wght@400;500&display=swap');
@import 'About';
@import 'Contact';
@import 'Header';
@import 'Meals';
@import 'Menu';
@import 'Queries';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}



/* ----- HEADINGS ----- */

h1,
h2,
h3 {
    font-family: 'Roboto Slab', serif;
    text-transform: uppercase;
}

h1 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
    font-size: 40px;
    word-spacing: 4px;
    letter-spacing: 1px;
}

h2 {
    font-size: 20%;
    word-spacing: 2px;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

p {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.479);
}
