.about-wrapper {


    margin-top: 30px;


    h2 {
        font-size: 40px;
        text-decoration: underline 3px #f4bf2b;
        color: rgb(54, 54, 54);
        margin-bottom: 30px;



    }

    .long-copy {
        margin: 0 auto;
        margin-bottom: 30px;
        line-height: 145%;
        font-weight: 300;
        width: 70%;
        color: rgb(54, 54, 54);



    }

    .about-card-container {
        display: flex;
        flex-direction: row;
        gap: 40px;
        padding: 80px 0;
        justify-content: center;
        flex-wrap: wrap;



        .cards {

            width: 33.33%;

            h3 {
                //text-decoration: underline 3px #f4bf2b;
                margin-top: 20px;
                margin-bottom: 20px;
                color: rgb(54, 54, 54);
            }

            img {
                width: 104px;
            }

            p {
                margin: 0 auto;
                margin-top: 20px;
                width: 70%;
                color: #363636;
                font-weight: 400;

            }

            ul {
                list-style: none;
                text-align: left;


                li {

                    &::before {
                        content: "-";
                        color: #f4bf2b;

                    }
                }
            }






        }

    }
}