/*----------------- Small phones: from 0 to 480px-----------------------*/

/*--Header--*/
@media screen and (max-width: 720px) {
    .logo {
        display: none;
    }

    .logoMobile {
        display: block !important;
        width: 135px;
        margin-bottom: 50px;
        margin-right: auto;
        margin-left: auto;
    }

    .header {
        width: 100%;
        height: 99vh;
        background-attachment: inherit;


        .hero-text-box {
            max-width: 400px;
            width: 85%;
            left: 50%;
            text-align: center;


            h1 {
                font-size: 46px;
                word-spacing: 2px;
                text-align: center;

            }

            .btn {
                padding: 5px, 10px;
                font-size: 15px;
                margin-bottom: 20px;
                text-align: center;
            }

            p {
                width: 100%;
                font-family: inherit;
                font-weight: 300;
                margin-bottom: 50px;
                text-align: center;
            }
        }

    }



    /*------Nav------*/

    .header-wrapper {

        .nav-wrapper {

            .logo {
                width: 55px;

            }
        }

        .nav {
            font-size: 18px;
            display: none;
        }
    }


    .mobile-nav {
        display: block;


        /* Position and sizing of burger button */
        .bm-burger-button {
            position: fixed;
            width: 36px;
            height: 30px;
            right: 36px;
            top: 36px;
        }

        /* Color/shape of burger icon bars */
        .bm-burger-bars {
            background: #f4bf2b;
        }

        /* Color/shape of burger icon bars on hover*/
        .bm-burger-bars-hover {
            background: hsla(44, 90%, 56%, 0.726);
        }

        /* Position and sizing of clickable cross button */
        .bm-cross-button {
            height: 66px;
            width: 66px;
        }

        /* Color/shape of close button cross */
        .bm-cross {
            background: #ffffff;
        }

        /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
        .bm-menu-wrap {
            position: fixed;
            height: 100%;
        }

        /* General sidebar styles */
        .bm-menu {
            background: #000000e0;
            padding: 2.5em 1.5em 0;
            font-size: 1.15em;
        }

        /* Morph shape necessary with bubble or elastic */
        .bm-morph-shape {
            fill: #373a47;
        }

        /* Wrapper for item list */
        .bm-item-list {
            color: #b8b7ad;
            padding: 0.8em;
            outline-color: none;


            a {
                color:white;
                text-decoration: none;
                margin-bottom: 20px;
                font-family: 'Roboto Slab';
                font-size: 27px;
               

                &:hover {
                    text-decoration: underline 4px solid hsla(44, 90%, 56%, 0.726);
                    color: white;


                }
            }
        }

        /* Individual item */
        .bm-item {
            display: inline-block;
            outline-style: none;
            outline-color: none;
            outline-width: 0px;

            
        }

        /* Styling of overlay */
        .bm-overlay {
            background: rgba(0, 0, 0, 0.3);
        }
    }







    /*-----------About--------------*/

    .about-wrapper {
        width: 100%;
        max-width: 400px;
        margin-top: 30px;
        margin-left: 25px;

        h2 {
            font-size: 30px;
        }


        .long-copy {
            width: 85%;
            text-align: left;
        }


        .about-card-container {
            flex-direction: column;




            .cards {
                width: 100%;
                text-align: left;


                h3 {
                    font-size: 21px;
                }

                svg {
                    font-size: 50px;
                }

                img {
                    width: 80px;
                }



                p {
                    text-align: left;
                    margin: 0;
                    margin-top: 20px;
                    width: 100%;
                }
            }
        }
    }


    /*-----------Menu--------------*/

    .menu-wrapper {


        .card-container {
            grid-template-columns: 1fr;
        }
    }


    /*-----------Menu--------------*/
    .meals-wrapper {

        .meal-show-container {


            li {
                width: 50%;
            }

        }
    }

    .contact-wrapper h2 {
        text-align: center;
        font-size: calc(1.325rem + .9vw);
    }

    .contact-wrapper .form-wrapper .left-para {
        text-align: center;
    }

    .contact-wrapper .form-wrapper form .submit-btn {
        width: 100%;
    }

    /*-----------Contact--------------*/

    .contact-wrapper {

        .form-wrapper {
            flex-direction: column;
            gap: 50px;



            form {
                width: 90%;
            }



            .contact-details {
                width: 90%;
                height: 300px;
                padding-top: 30px;
                padding-bottom: 30px;


                ion-icon {
                    font-size: 40px;
                }
            }
        }
    }

    button.menu-button.btn.btn-primary {
        display: block;
        position: fixed;
        right: 6px;


    }

    .menu-button.btn.btn-primary {
        background-color: rgba(0, 0, 0, 0.212);
        border: none;
        font-size: 30px;
        outline: 0;

        &:focus {
            outline: none !important;
            box-shadow: none;
        }

        &:active {
            border: none;
            box-shadow: none;
        }

        &:hover {
            border: none;
        }

    }


}



/*----------------------- Small phones to small tablets: from 481px to 767px------------------*/
@media screen and (max-width: 720px) {

    /*-------Header-------*/






    /*-----------About--------------*/

    .about-wrapper {
        width: 100%;
        margin: 46px auto;



        .long-copy {

            text-align: left;
            font-size: 16px;
            line-height: 24px;
        }


        .about-card-container {
            width: 85%;
            margin-right: auto;
            margin-left: auto;
            flex-direction: column;
            padding-top: 10%;



            .cards {
                width: 100%;
                text-align: center;

                h3 {
                    font-size: 24px;
                }


                svg {
                    font-size: 60px;
                }

                img {
                    width: 100px;
                }


                p {
                    text-align: center;
                    font-weight: 300;
                    margin: 0;
                    margin-top: 20px;
                    width: 100%;
                }
            }
        }
    }








}