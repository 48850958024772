.meals-wrapper {
    padding: 0px;



    .meal-show-container {
        padding: 0;
        list-style: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
            width: 33.33%;


            figure {
                width: 100%;
                margin: 0;
                overflow: hidden;


                img {
                    width: 100%;
                    height: auto;
                    -webkit-transform: scale(1.10);
                    -ms-transform: scale(1.10);
                    transform: scale(1.10);
                    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
                    transition: transform 0.5s, opacity 0.5s;




                }

                img:hover {

                    opacity: 1;
                    -webkit-transform: scale(1.00);
                    -ms-transform: scale(1.00);
                    transform: scale(1.00);



                }
            }
        }
    }
}