.contact-wrapper {
    height: 75vh;
    margin-top: 40px;

    h2 {
        font-size: 40px;
        text-decoration: underline 3px #f4bf2b;
        color: rgb(54, 54, 54);
        margin-bottom: 30px;
        text-align: left;
    }

    .form-wrapper {

        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;


        .left-para {
            text-align: left;
        }


        .visible {
            display: block;
            font-size: 24px;
            color: rgb(54, 54, 54);
        }

        .hidden {
            display: none;
        }





        form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 40%;



            textarea,
            input {
                padding: 10px;
                border: 0px;
                border-bottom: 2px solid #ccc;
                text-align: left;
                font-family: 'Roboto Slab';

            }






            textarea {
                height: 150px;
            }

            .submit-btn {
                width: 40%;
                color: white;
                padding: 10px 20px;
                background: hsla(44, 69%, 45%, 0.863);
                border-radius: 30px;
                font-family: 'Roboto Slab', serif;
                font-size: 18px;
                border: 0px;

            }
        }

        .contact-details {
            background-color: rgb(0, 0, 0);
            height: 550px;
            width: 50%;
            padding-top: 60px;
            padding-bottom: 60px;
            gap: 10px;

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .number a {
                text-decoration: none;
                color: white;
                margin-bottom: 10px;
                font-weight: 500;
            }


            ion-icon {
                color: hsla(44, 69%, 45%, 0.86);
                font-size: 60px;
            }

            p {
                color: white;
            }
        }
    }
}